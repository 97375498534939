@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --tw-bg-opacity: 1;
  background-color: rgb(25 26 31 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
  /* font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
  border-radius: 1rem/* 16px */;
  border: 1px solid #191a1f;
}

::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(119 119 119 / var(--tw-bg-opacity));
}
::-webkit-scrollbar-button {
  display: none;
}

.override-hidden{
  display: none;
}

#video_player > .ytp-chrome-top{
  display:none;
}